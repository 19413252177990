import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Utilities
import * as storage from '@/utils/storage';

// Plugins
import { Tooltip } from 'react-tippy';
import { debounce } from 'lodash';
import { useActionCable } from 'use-action-cable';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import TopBar from './TopBar';
import Header from '../Header';
import GalleryDragElement from './GalleryDragElement';
import Lightbox from '@/components/Shared/Lightbox';
import PhotoGrid from './PhotoGrid';
import TagsSidebar from './Tags/Sidebar';
import PeopleMatch from './People/Match';
import TagManager from './Tags/TagManager';
import PeopleSidebar from './People/Sidebar';
import PeopleAddEdit from './People/AddEdit';
import UploadDropzone from './Upload/Dropzone';
import BulkFeature from './People/BulkFeature';
import BulkYearbookSelection from './People/BulkYearbookSelection';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import QrResolution from './QrResolution';
import GalleryOnboardingPrivate from '../OnBoarding/GalleryPrivateOnboarding';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  updateJob,
  getPhotoList,
  updatePhotoStats,
  updatePhotoTags,
  updatePhotoGallery,
  updatePhotoTrainedAndRemoveQrImages,
  updatePhotoDropzone,
  deletePhoto,
  setPhotoQueryComponent,
  createTagAssociation,
  deleteTagAssociation,
  getTagList,
  getPeopleList,
  getSidebarPeopleList,
  getPeopleFacesList,
  updatePeople,
  deletePeoplePhoto,
  setPeopleDetails,
  setPeoplePhotosCount,
  updatePhotos,
  getJobQrSessions
} from '../actions';
import { setProfileFlagsRequest } from '@/components/Login/actions';

// Styles
import './style.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

// Constants
import { SIDEBAR_COLLAPSED } from '../constants';
const CAPTURE_QR_ENABLED = import.meta.env.VITE_CAPTURE_QR_ENABLED === '1';
const KNOCK_OUT_BACKGROUND_ENABLED = import.meta.env.VITE_KNOCK_OUT_BACKGROUND_ENABLED === '1';

function Gallery({ location, match, history }) {
  const uploadDropzoneRef = useRef();
  const galleryDragElementRef = useRef();

  const dispatch = useDispatch();
  const {
    params: { jobId }
  } = match;
  const {
    job,
    query: { people: peopleQuery, tags: tagsQuery },
    photos: { list: photos, requesting: photosRequesting, pagination, successful: photosSuccessful },
    tags: { list: tags },
    people: { list: people, sidebarList, fields, fieldsMap, requesting: peopleRequesting },
    faces: { list: faces, requesting: facesRequesting },
    upload: { queue: uploadQueue, successful: uploadSuccessful }
  } = useSelector((state) => state.jobs);

  const { photo_stats: photoStats, biometrics_enabled: jobBiometricsEnabled, qr_enabled: jobQrEnabled, qr_sessions: jobQrSessions } = job;

  const {
    isAdmin,
    studio,
    entities: { flags }
  } = useSelector((state) => state.login);
  const { feature_flags: studioFeatureFlags } = studio;

  const lastSidebarState = storage.get(SIDEBAR_COLLAPSED);
  const userFlags = Object.values(flags).length ? Object.values(flags)[0] : {};

  const isStudioCaptureQrAllowed = CAPTURE_QR_ENABLED && studioFeatureFlags?.allow_capture_qr ? true : false;
  const isQrDetectionProcessing = photoStats.photos > 0 && photoStats.photos_trained !== photoStats.photos;
  const qrSessionsTotalErrors = jobQrSessions?.reduce((total, qrSession) => total + qrSession.errors_count, 0);

  // RULES:
  // PNG NOT allowed WHEN job is in published state without a featured background
  // PNG NOT allowed WHEN Jobs price sheet lab does NOT allowed background feature
  const jobPricesheetLab = studio.labs?.find((lab) => lab.id === job.meta?.lab_id);
  const isJobPublishedWithoutFeaturedBg = job.job_status === 'onsale' && job.featured_background === null;
  const isPngImageAllowed = KNOCK_OUT_BACKGROUND_ENABLED && jobPricesheetLab?.allow_backgrounds && !isJobPublishedWithoutFeaturedBg;

  const shouldGalleryGridBeDisabled = () => {
    // If there is any QR session with errors, we should disable the gallery grid
    if (qrSessionsTotalErrors > 0) {
      return true;
    }

    return false;
  };

  const previousPhotoHoveredRef = useRef('');

  const [filterTags, setFilterTags] = useState([]);
  const [filterPeople, setFilterPeople] = useState({});
  const [filterPhotos, setFilterPhotos] = useState('all');

  const [faceSelected, setFaceSelected] = useState('');
  const [photoSelected, setPhotoSelected] = useState([]);
  const [allPhotosSelected, setAllPhotosSelected] = useState(false);
  const [unselectedPhotos, setUnselectedPhotos] = useState([]);

  const [previousPhotoSelected, setPreviousPhotoSelected] = useState('');
  const [shiftHoverSelected, setShiftHoverSelected] = useState([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const [isUpPressed, setIsUpPressed] = useState(false);
  const [isDownPressed, setIsDownPressed] = useState(false);
  const [nextSubject, setNextSubject] = useState({});

  const [downloadsDisabled, setDownloadsDisabled] = useState('');

  const [galleryType, setGalleryType] = useState('');
  const [currentPhotoList, setCurrentPhotoList] = useState([]);

  const [search, setSearch] = useState('');
  const [shouldClearSubjectsSearchBy, setShouldClearSubjectsSearchBy] = useState(false);

  const [showTag, setShowTag] = useState(false);
  const [tagManagerOpenList, setTagManagerOpenList] = useState([]);

  const [peopleManagerOpenList, setPeopleManagerOpenList] = useState([]);

  const [showLightbox, setShowLightbox] = useState(false);

  const [showMatch, setShowMatch] = useState(false);
  const [showPeopleAddEdit, setShowPeopleAddEdit] = useState(false);

  const [showBulkFeature, setShowBulkFeature] = useState(false);
  const [showBulkYearbookSelect, setShowBulkYearbookSelect] = useState(false);

  const [showQrResolutionModal, setShowQrResolutionModal] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const [showOnboardingPhotos, setShowOnboardingPhotos] = useState(false);
  const [showDropzoneSplash, setShowDropzoneSplash] = useState(false);
  const [showDropzoneSplashEnable, setShowDropzoneSplashEnable] = useState(true);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(lastSidebarState === 'true');
  const [isFlyoverOpen, setIsFlyeroverOpen] = useState(false);
  const [isQrDetectionComplete, setIsQrDetectionComplete] = useState(true);

  const updateProfileFlag = (flag) => {
    dispatch(setProfileFlagsRequest(flag));
  };

  const revealBulkFeature = () => {
    if (userFlags.seen_bulk_feature || isAdmin) return;

    setTimeout(() => {
      setShowBulkFeature(true);
    }, 5000);
  };

  const handleOnboardingPhotos = (disableShow) => {
    setShowOnboardingPhotos(false);

    if (disableShow) {
      updateProfileFlag({ onboarding_photos_seen: disableShow });
    }
  };

  const handleSidebarCollapse = () => setIsSidebarCollapsed(!isSidebarCollapsed);

  // Infinite Scroll
  const handleInfiniteScroll = () => {
    return new Promise((resolve) => {
      dispatch(
        getPhotoList(
          {
            id: jobId,
            page: Number(pagination.page + 1),
            per_page: Number(pagination.perPage) || 100,
            ...(search ? { search } : {}),
            ...(filterTags.length ? { tags: filterTags.toString() } : {}),
            ...(filterPhotos === 'untagged' ? { untagged: true } : {}),
            ...(filterPhotos === 'unmatched' ? { unmatched_faces: true } : {}),
            ...(filterPhotos === 'last_uploaded' ? { last_uploaded: true } : {}),
            ...(filterPhotos === 'has_transparency' ? { has_transparency: true } : {}),
            ...(filterPeople?.id ? { subject_id: filterPeople.id } : {})
          },
          ({ data }) => {
            if (allPhotosSelected) {
              setPhotoSelected([...photoSelected, ...data.map((photo) => photo.id)]);
            }

            resolve();
          }
        )
      );
    });
  };

  // Photos selection
  const handlePhotoDragStart = (e, photo) => {
    e.dataTransfer.setDragImage(galleryDragElementRef?.current, 60, 60);

    if (photoSelected.length === 0) {
      handlePhotoSelect(photo);
    }

    if (isSidebarCollapsed && !isFlyoverOpen) {
      setIsFlyeroverOpen(true);
    }

    setShowDropzoneSplashEnable(false);
  };

  const handlePhotoDragEnd = () => {
    handlePhotoSelectClear();
    setShowDropzoneSplashEnable(true);

    if (isSidebarCollapsed && isFlyoverOpen) {
      setIsFlyeroverOpen(false);
    }
  };

  const handleMouseEnter = (e, photo) => {
    e.preventDefault();

    if (photoSelected.length) {
      previousPhotoHoveredRef.current = photo;

      if (e.shiftKey) {
        const start = previousPhotoSelected
          ? currentPhotoList.findIndex((item) => item.id === previousPhotoSelected)
          : currentPhotoList.findIndex((item) => item.id === photoSelected[0]);
        const end = currentPhotoList.findIndex((item) => item.id === photo);

        setShiftHoverSelected(currentPhotoList.slice(Math.min(start, end), Math.max(start, end) + 1).map((item) => item.id));
      } else if (shiftHoverSelected.length) {
        setShiftHoverSelected([]);
      }
    }
  };

  const handleShiftSelect = () => {
    if (photoSelected.length && previousPhotoHoveredRef.current) {
      const start = previousPhotoSelected
        ? currentPhotoList.findIndex((item) => item.id === previousPhotoSelected)
        : currentPhotoList.findIndex((item) => item.id === photoSelected[0]);
      const end = currentPhotoList.findIndex((item) => item.id === previousPhotoHoveredRef.current);

      setShiftHoverSelected(currentPhotoList.slice(Math.min(start, end), Math.max(start, end) + 1).map((item) => item.id));
    }
  };

  const handleKeyDown = useRef(
    debounce(
      (e) => {
        switch (e.which) {
          case 16:
            return setIsShiftPressed(true);
          case 38:
            e.preventDefault();
            return setIsUpPressed(true);
          case 40:
            e.preventDefault();
            return setIsDownPressed(true);
          case 219:
            return setIsSidebarCollapsed(true);
          case 221:
            return setIsSidebarCollapsed(false);
          default:
            return;
        }
      },
      50,
      { leading: true, trailing: false }
    )
  ).current;

  const handleKeyUp = (e) => {
    switch (e.which) {
      case 16:
        return setIsShiftPressed(false);
      case 38:
        return setIsUpPressed(false);
      case 40:
        return setIsDownPressed(false);
      default:
        return;
    }
  };

  const handlePhotoSelect = (photoId, bypass = false) => {
    const currentPhotoSelected = photoSelected;

    let newPhotoSelected = [];

    if (shiftHoverSelected.length) {
      const previousPhotoIsSelected = currentPhotoSelected.includes(previousPhotoSelected);

      if (previousPhotoIsSelected) {
        newPhotoSelected = [...currentPhotoSelected, ...shiftHoverSelected];
      } else {
        newPhotoSelected = currentPhotoSelected.filter((photo) => !shiftHoverSelected.includes(photo));
      }
    } else {
      if (currentPhotoSelected.includes(photoId)) {
        newPhotoSelected = bypass ? currentPhotoSelected : currentPhotoSelected.filter((item) => item !== photoId);
      } else {
        newPhotoSelected = bypass ? [photoId] : [...currentPhotoSelected, photoId];
      }
    }

    const filterUnselected = currentPhotoSelected.filter((photo) => !newPhotoSelected.includes(photo));
    const filterSelected = unselectedPhotos.filter((photo) => !newPhotoSelected.includes(photo));

    setShiftHoverSelected([]);
    setPhotoSelected(Array.from(new Set(newPhotoSelected)));

    !isShiftPressed && setPreviousPhotoSelected(photoId);
    allPhotosSelected && setUnselectedPhotos([...filterUnselected, ...filterSelected]);
  };

  const handlePhotoSelectAll = () => {
    setPhotoSelected(currentPhotoList.map((photo) => photo.id));
    setAllPhotosSelected(true);
    setUnselectedPhotos([]);
    setShiftHoverSelected([]);
    setPreviousPhotoSelected('');
  };

  const handlePhotoSelectClear = () => {
    setShiftHoverSelected([]);
    setPhotoSelected([]);
    setUnselectedPhotos([]);
    setAllPhotosSelected(false);
    setPreviousPhotoSelected('');
  };

  const handlePhotosSearchChange = (e) => setSearch(e.target.value);
  const handlePhotosSearchClear = (e) => {
    e.stopPropagation();

    setSearch('');
    setPhotoSelected([]);

    if (peopleQuery === 'search' || tagsQuery === 'search') {
      handlePhotosFilter('all');
    }
  };
  const handlePhotosSearch = (e) => {
    e.preventDefault();

    setPhotoSelected([]);
    setFilterTags([]);
    setFilterPeople({});
    setFilterPhotos('');
    setFilterPhotos('all');
    dispatch(
      getPhotoList({ id: jobId, page: 1, per_page: Number(pagination.perPage) || 100, search, reset: true }, () => {
        dispatch(setPhotoQueryComponent({ people: 'search', tags: 'search' }));
      })
    );
  };

  const handlePhotosSort = (sort) => {
    dispatch(
      updateJob({ id: jobId, sort_type: sort }, () => {
        dispatch(
          getPhotoList({
            id: jobId,
            page: 1,
            per_page: Number(pagination.perPage) || 100,
            tags: filterTags.length ? filterTags.toString() : null,
            untagged: filterPhotos === 'untagged' ? true : null,
            subject_id: Object.keys(filterPeople)?.length && filterPeople?.id ? filterPeople.id : null,
            unmatched_faces: filterPhotos === 'unmatched' ? true : null,
            reset: true
          })
        );
      })
    );
  };

  const handleShowLightboxToggle = () => setShowLightbox(!showLightbox);

  const handleShowMatchToggle = () => setShowMatch(!showMatch);

  const handleShowBulkFeatureToggle = () => {
    setShowBulkFeature(!showBulkFeature);

    if (!userFlags.seen_bulk_feature && !isAdmin) updateProfileFlag({ seen_bulk_feature: true });
  };

  const handleShowBulkYearbookToggle = () => setShowBulkYearbookSelect(!showBulkYearbookSelect);

  const handleQrResolutionModalToggle = () => setShowQrResolutionModal(!showQrResolutionModal);

  const handlePhotoFeature = (action, photoId, subjectId) => {
    setPhotoSelected([]);
    dispatch(updatePeople({ id: subjectId || filterPeople?.id, primary_photo_id: action === 'feature' ? (photoId ? photoId : photoSelected[0]) : '' }));

    if (!subjectId || (subjectId && filterPeople?.id === subjectId)) {
      setFilterPeople({ ...filterPeople, primary_photo_id: action === 'feature' ? (photoId ? photoId : photoSelected[0]) : '' });
    }
  };

  const handlePhotoRemove = () => {
    if (filterPeople?.id) {
      dispatch(
        deletePeoplePhoto({ id: filterPeople.id, photo_ids: photoSelected, perform_all: allPhotosSelected, unselected_photo_ids: unselectedPhotos }, () => {
          handlePhotoSelectClear();
        })
      );
    }
  };

  const handleDeleteShow = () => setShowDelete(true);
  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);
  const handleDeleteCancel = () => {
    setConfirmDelete('');
    setShowDelete(false);
  };
  const handleDelete = () => {
    dispatch(
      deletePhoto(
        {
          search,
          id: jobId,
          photo_ids: photoSelected,
          perform_all: allPhotosSelected,
          unselected_photo_ids: unselectedPhotos,
          filter_tags: filterTags.length ? filterTags : null,
          untagged: filterPhotos === 'untagged' ? true : null,
          tags: filterTags.length ? filterTags.toString() : null,
          unmatched_faces: filterPhotos === 'unmatched' ? true : null,
          last_uploaded: filterPhotos === 'last_uploaded' ? true : null,
          has_transparency: filterPhotos === 'has_transparency' ? true : null,
          subject_id: Object.keys(filterPeople)?.length && filterPeople?.id ? filterPeople.id : null
        },
        () => {
          if (allPhotosSelected) {
            handlePhotosFilter('all');
          } else {
            dispatch(
              getPhotoList({
                page: 1,
                id: jobId,
                reset: true,
                per_page: Number(pagination.perPage) || 100,
                untagged: filterPhotos === 'untagged' ? true : null,
                tags: filterTags.length ? filterTags.toString() : null,
                last_uploaded: filterPhotos === 'last_uploaded' ? true : null,
                has_transparency: filterPhotos === 'has_transparency' ? true : null,
                unmatched_faces: filterPhotos === 'unmatched' ? true : null,
                subject_id: Object.keys(filterPeople)?.length && filterPeople?.id ? filterPeople.id : null
              })
            );
          }

          if (galleryType !== 'private') {
            dispatch(getTagList({ id: jobId }));
          } else {
            const listArgs = { id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' };

            dispatch(getPeopleList(listArgs));
            dispatch(getSidebarPeopleList(listArgs));
          }

          setPhotoSelected([]);
          handleDeleteCancel();
        }
      )
    );
  };

  // Faces selection
  const handleFaceSelect = (faceId) => setFaceSelected(faceId);
  const handleFaceSelectClear = () => setFaceSelected('');

  // Tags
  const handleTagModalShow = () => {
    setShowTag(true);
    setTagManagerOpenList([]);
  };

  const handleTagManagerShow = (photoId) => {
    const newTagList = tagManagerOpenList.includes(photoId) ? tagManagerOpenList.filter((tag) => tag !== photoId) : [...tagManagerOpenList, photoId];

    setPhotoSelected([]);
    setTagManagerOpenList(newTagList);
  };

  const handlePeopleManagerShow = (photoId) => {
    const newPeopleList = peopleManagerOpenList.includes(photoId)
      ? peopleManagerOpenList.filter((tag) => tag !== photoId)
      : [...peopleManagerOpenList, photoId];

    setPhotoSelected([]);
    setPeopleManagerOpenList(newPeopleList);
  };

  const handlePeopleAddEditToggle = () => {
    setShowPeopleAddEdit(!showPeopleAddEdit);
  };

  const handleTagModalCancel = () => {
    setShowTag(false);
  };

  const handleRemoveTagsFromPhotos = (removeAllTags) => {
    if (removeAllTags) {
      dispatch(
        createTagAssociation(
          {
            id: jobId,
            photo_ids: photoSelected,
            tags: [],
            untagged: filterPhotos === 'untagged' ? true : null,
            filter_tags: filterTags.length ? filterTags : null,
            perform_all: allPhotosSelected,
            unselected_photo_ids: unselectedPhotos,
            replace: true
          },
          () => {
            handlePhotoSelectClear();
            dispatch(getTagList({ id: jobId }));
            dispatch(
              getPhotoList({
                id: jobId,
                page: 1,
                per_page: Number(pagination.perPage) || 100,
                tags: filterTags.length ? filterTags.toString() : null,
                untagged: filterPhotos === 'untagged' ? true : null,
                reset: true
              })
            );
          }
        )
      );
    } else {
      dispatch(
        deleteTagAssociation(
          {
            id: jobId,
            photo_ids: photoSelected,
            tags: filterTags,
            untagged: filterPhotos === 'untagged' ? true : null,
            filter_tags: filterTags.length ? filterTags : null,
            perform_all: allPhotosSelected,
            unselected_photo_ids: unselectedPhotos
          },
          () => {
            handlePhotoSelectClear();
            dispatch(getTagList({ id: jobId }));
            dispatch(
              getPhotoList({
                id: jobId,
                page: 1,
                per_page: Number(pagination.perPage) || 100,
                tags: filterTags.length ? filterTags.toString() : null,
                untagged: filterPhotos === 'untagged' ? true : null,
                reset: true
              })
            );
          }
        )
      );
    }
  };

  const handlePhotosTagFilter = (tags = []) => {
    if (!galleryType || galleryType === 'private') return;

    const params = !tags.length ? 'all' : tags;

    setSearch('');
    setFilterTags(tags);
    setPhotoSelected([]);

    if (filterPhotos !== 'tags' && tags.length > 0) {
      setFilterPhotos('tags');
    }

    if (params !== 'all' || !photos.length) {
      dispatch(
        getPhotoList({
          id: jobId,
          page: 1,
          per_page: Number(pagination.perPage) || 100,
          tags: tags.length ? tags.toString() : null,
          reset: true
        })
      );
    }
    history.push({ search: params !== 'all' ? `?tags=${params}` : '' });
    dispatch(setPhotoQueryComponent({ tags: params }));
  };

  // Filters
  const handleInitialFilters = () => {
    if (tagsQuery && tagsQuery !== 'all') {
      if (tagsQuery === 'untagged') {
        return handlePhotosFilter('untagged');
      }

      if (typeof tagsQuery === 'object' && tagsQuery.length) {
        return handlePhotosTagFilter(tagsQuery);
      }
    }

    if (peopleQuery && peopleQuery !== 'all') {
      const findSubject = (id) => people.find((person) => person.id === id);
      const subject = findSubject(peopleQuery);

      switch (peopleQuery) {
        case 'unmatched':
          return handlePhotosFilter('unmatched');
        case 'ignored':
          return handlePhotosFilter('ignored');
        case 'featured':
          return handlePhotosFilter('featured');
        case 'yearbook':
          return handlePhotosFilter('yearbook');
        default:
          if (subject) {
            return handlePhotosPeopleFilter(subject);
          }

          return;
      }
    }

    handlePhotosFilter('all');
  };

  const handlePhotosFilter = (filter) => {
    setSearch('');
    setFilterTags([]);
    setFaceSelected('');
    setPhotoSelected([]);
    setFilterPhotos(filter);
    setPeopleManagerOpenList([]);

    if (filter === 'all') {
      galleryType && galleryType !== 'private' && handlePhotosTagFilter([]);
      galleryType && dispatch(setPhotoQueryComponent(galleryType !== 'private' ? { tags: 'all' } : { people: 'all' }));
      dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, reset: true }));
    }

    if (filter === 'unmatched') {
      dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, unmatched_faces: true, reset: true }));
      dispatch(setPhotoQueryComponent({ people: 'unmatched' }));
    }

    if (filter === 'untagged') {
      handlePhotosTagFilter([]);
      dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, untagged: true, reset: true }));
      dispatch(setPhotoQueryComponent({ tags: 'untagged' }));
    }

    if (filter === 'ignored') {
      dispatch(getPeopleFacesList({ id: jobId, ignored: true }));
      dispatch(setPhotoQueryComponent({ people: 'ignored' }));
    }

    if (filter === 'featured') {
      dispatch(setPhotoQueryComponent({ people: 'featured' }));
      revealBulkFeature();
    }

    if (filter === 'yearbook') {
      dispatch(setPhotoQueryComponent({ people: 'yearbook' }));
    }

    if (filter === 'last_uploaded') {
      dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, last_uploaded: true, reset: true }));

      if (galleryType && galleryType !== 'private') {
        dispatch(setPhotoQueryComponent({ tags: 'last_uploaded' }));
      } else {
        dispatch(setPhotoQueryComponent({ people: 'last_uploaded' }));
      }
    }

    if (filter === 'has_transparency') {
      dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, has_transparency: true, reset: true }));

      if (galleryType && galleryType !== 'private') {
        dispatch(setPhotoQueryComponent({ tags: 'has_transparency' }));
      } else {
        dispatch(setPhotoQueryComponent({ people: 'has_transparency' }));
      }
    }

    if (filter === 'subjects') {
      if (sidebarList.length > 0 && !filterPeople?.id) {
        const subject = sidebarList[0];

        setIsSidebarCollapsed(false);
        dispatch(setPhotoQueryComponent({ people: subject.id }));
      }
    }

    if (galleryType === 'private' && filter !== 'subjects' && filterPeople?.id) {
      setFilterPeople({});
    }

    if (filter === 'tags' && tags.length) {
      const firstWithPhotos = tags.find((tag) => tag.photo_count);

      setIsSidebarCollapsed(false);
      handlePhotosTagFilter([firstWithPhotos ? firstWithPhotos.name : tags[0].name]);
    }

    // remove focus from dropdown so up and down keys don't interfere with subject sidebar selection
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const handlePhotosPeopleFilter = useRef(
    debounce(
      (person) => {
        setSearch('');
        setFaceSelected('');
        setPhotoSelected([]);
        setPeopleManagerOpenList([]);

        if (person.id) {
          setFilterPeople(person);
          setNextSubject({});

          dispatch(setPhotoQueryComponent({ people: person.id }));
          dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, subject_id: person.id, unmatched_faces: null, reset: true }));

          if (filterPhotos !== 'subjects') {
            setFilterPhotos('subjects');
          }
        }
      },
      100,
      { leading: true, trailing: false }
    )
  ).current;

  const handleDigitalDownload = (forbid, photoId) => {
    dispatch(
      updatePhotos(
        {
          id: jobId,
          ...(search ? { search } : {}),
          ...(allPhotosSelected ? { perform_all: true } : {}),
          ...(filterPhotos === 'untagged' ? { untagged: true } : {}),
          ...(photoSelected ? { photo_ids: photoId ? [photoId] : photoSelected } : {}),
          ...(unselectedPhotos?.length ? { unselected_photo_ids: unselectedPhotos } : {}),
          ...(filterTags?.length ? { tags: filterTags.toString() } : {}),
          ...(filterTags?.length ? { filter_tags: filterTags } : {}),
          ...(filterPeople?.id ? { subject_id: filterPeople.id } : {}),
          ...(filterPhotos === 'unmatched' ? { unmatched_faces: true } : {}),
          ...(filterPhotos === 'last_uploaded' ? { last_uploaded: true } : {}),
          attributes: { forbid_download: forbid }
        },
        () => {
          handlePhotoSelectClear();
        }
      )
    );
  };

  // Dropzone
  const handleDragEnter = (e) => {
    const types = e.dataTransfer.types;
    const hasImageFiles = types.length && types.some((type) => type === 'Files');

    if (hasImageFiles) {
      handleDropzoneSplashToggle(true);
    }
  };

  const handleDropzoneSplashToggle = (show = false) => {
    if (showDropzoneSplashEnable && !uploadQueue?.length) {
      setShowDropzoneSplash(show);
    }
  };

  const handleUploadMessageReceive = {
    received(message) {
      if (message.type === 'photo_failed') {
        const { details } = message;

        dispatch(
          updatePhotoDropzone({
            jobId,
            errored: [{ category: details?.error_category, filename: details?.filename, message: details?.error_message }],
            successful: Number(uploadSuccessful - 1)
          })
        );
      }

      if (message.type === 'photo_stats') {
        dispatch(updatePhotoStats(message));
      }

      if (message.type === 'photo_tags_updated') {
        dispatch(updatePhotoTags(message));
      }

      if (message.type === 'photo_complete') {
        dispatch(updatePhotoGallery(message));
      }

      if (message.type === 'photo_trained') {
        dispatch(updatePhotoGallery(message));
      }

      if (message.type === 'qr_detection_complete') {
        setIsQrDetectionComplete(true);
        dispatch(updatePhotoTrainedAndRemoveQrImages(message));
      }

      if (message.type === 'qr_sessions_updated') {
        dispatch(getJobQrSessions({ jobId }));
      }

      if (message.type === 'subjects_matched') {
        dispatch(setPeoplePhotosCount(message));
      }

      if (message.type === 'subjects_updated') {
        dispatch(setPeopleDetails(message));
      }

      if (message.type === 'subject_refresh') {
        dispatch(setPeopleDetails({ subjects: [message?.subject] }));
      }
    }
  };

  const handleToggleIsMenuOpen = (isOpen) => setIsMenuOpen(isOpen);

  const loadNextSubject = useRef(
    // Delay loading nextSubject until user has stopped key presses for 200ms
    debounce(
      (subject) => {
        handlePhotosPeopleFilter(subject);
      },
      500,
      { leading: false, trailing: true }
    )
  ).current;

  const handleNavigateSubjects = (prev, next) => {
    if (isMenuOpen) return;

    const subjectIndex = sidebarList.findIndex((subject) => (nextSubject.id ? subject.id === nextSubject.id : subject.id === filterPeople.id));

    if (subjectIndex >= 0) {
      let newSubject;

      if (prev && subjectIndex !== 0) {
        newSubject = sidebarList[subjectIndex - 1];
      }

      if (next && subjectIndex !== sidebarList.length - 1) {
        newSubject = sidebarList[subjectIndex + 1];
      }

      if (newSubject && newSubject.id) {
        // allow sidebar to reflect nextSubject while delaying request for nextSubject photos
        loadNextSubject(newSubject);
        setNextSubject(newSubject);
      }
    }
  };

  useActionCable({ channel: 'JobEventsChannel', job_id: jobId }, handleUploadMessageReceive);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (job?.id) {
      if (job.access_mode === 'access_per_subject' && !job.setup_steps.uploaded_photos && !userFlags.onboarding_photos_seen) {
        setShowOnboardingPhotos(true);
      }
      handlePhotosFilter('all');
      setGalleryType(job.access_mode === 'access_per_subject' ? 'private' : 'public');
    } else {
      handleInitialFilters();
      setGalleryType(job.access_mode === 'access_per_subject' ? 'private' : 'public');
      if (job.access_mode === 'access_per_subject' && !job.setup_steps.uploaded_photos && !userFlags.onboarding_photos_seen) {
        setShowOnboardingPhotos(true);
      }
    }
  }, [job?.id]);

  useEffect(() => {
    // !TODO consider removing CurrentPhoto logic
    setCurrentPhotoList(photos);
  }, [photos]);

  useEffect(() => {
    const photoIds = faces.filter((face) => faceSelected.includes(face.id)).map((face) => face.photo_id);

    setPhotoSelected([...new Set(photoIds)]);
  }, [faceSelected]);

  useEffect(() => {
    if (isShiftPressed) {
      handleShiftSelect();
    }
  }, [isShiftPressed]);

  useEffect(() => {
    const allSelected = currentPhotoList.filter((photo) => photoSelected.includes(photo.id));
    const allForbidDownload = allSelected.every((photo) => photo.forbid_download);
    const noneForbidDownload = allSelected.every((photo) => !photo.forbid_download);
    const newTagManagerOpenList = tagManagerOpenList.filter((photoId) => photos.some((photo) => photo.id === photoId));

    if (allForbidDownload) {
      downloadsDisabled !== 'all' && setDownloadsDisabled('all');
    } else if (noneForbidDownload) {
      downloadsDisabled !== 'none' && setDownloadsDisabled('none');
    } else {
      downloadsDisabled !== '' && setDownloadsDisabled('');
    }

    if (!photoSelected.length) {
      setAllPhotosSelected(false);
    }

    if (tagManagerOpenList.length !== newTagManagerOpenList.length) {
      setTagManagerOpenList(newTagManagerOpenList);
    }
  }, [photoSelected, photos]);

  useEffect(() => {
    if (galleryType && galleryType !== 'private' && tagManagerOpenList.length === 0) {
      dispatch(getTagList({ id: jobId }));
    }
  }, [tagManagerOpenList]);

  useEffect(() => {
    if (filterPeople.id && (isUpPressed || isDownPressed)) {
      handleNavigateSubjects(isUpPressed, isDownPressed);

      isUpPressed && setIsUpPressed(false);
      isDownPressed && setIsDownPressed(false);
    }
  }, [isUpPressed, isDownPressed]);

  useEffect(() => {
    if (filterPeople.id !== peopleQuery) {
      const newActivePerson = people.find((person) => person.id === peopleQuery);

      if (newActivePerson) {
        setFilterPeople(newActivePerson);

        dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, subject_id: peopleQuery, unmatched_faces: null, reset: true }));
      }
    }
  }, [peopleQuery]);

  useEffect(() => {
    const foundPerson = sidebarList.find((person) => person.id === filterPeople.id);

    if (foundPerson && JSON.stringify(foundPerson) !== JSON.stringify(filterPeople)) {
      setFilterPeople(foundPerson);
    }

    if (!foundPerson && !filterPeople?.unmatched && !filterPeople?.featured && Object.keys(filterPeople)?.length > 0) {
      setFilterPeople({});
    }
  }, [sidebarList]);

  useEffect(() => {
    return () => {
      // maintain user last state for sidebar
      storage.set(SIDEBAR_COLLAPSED, isSidebarCollapsed);
    };
  }, [isSidebarCollapsed]);

  useEffect(() => {
    if (uploadQueue?.length > 0 && filterPhotos === 'last_uploaded') {
      // Go to All Photos when photos are being uploaded
      // Last Uploaded option is removed while photos are still uploading
      handlePhotosFilter('all');
    }

    if (jobQrEnabled && uploadQueue.length > 0) {
      setIsQrDetectionComplete(false);
    }
  }, [uploadQueue]);

  useEffect(() => {
    if (photoStats?.last_uploaded === 0 && filterPhotos === 'last_uploaded') {
      handlePhotosFilter('all');
    }
  }, [photoStats]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Photos" filterPeople={filterPeople} />

      <main className="container flex relative job-gallery" onDragEnter={handleDragEnter} onDrop={() => handleDropzoneSplashToggle(false)}>
        {/* Main Sidebar */}
        <aside
          className={`job-gallery__sidebar ${filterPhotos === 'tags' || filterPhotos === 'subjects' ? 'job-gallery__sidebar--mobile' : ''} ${
            isSidebarCollapsed ? 'job-gallery__sidebar--collapsed' : ''
          } ${isFlyoverOpen ? 'job-gallery__sidebar--flyover' : ''} ${showDropzoneSplash ? 'job-gallery__sidebar--splash' : ''}`}
        >
          <button className={`job-gallery__sidebar-handle button button--outline button--small`} type="button" onClick={handleSidebarCollapse}>
            <Tooltip title={isSidebarCollapsed ? 'open sidebar: ]' : 'close sidebar: ['} position="right" arrow={false} distance="10" delay={200}>
              <i className="icon-arrow-left--dark"></i>
            </Tooltip>
          </button>

          {/* Tags Sidebar */}
          {galleryType === 'public' && (
            <TagsSidebar
              jobId={jobId}
              location={location}
              filterTags={filterTags}
              filterPhotos={filterPhotos}
              photoSelected={photoSelected}
              unselectedPhotos={unselectedPhotos}
              allPhotosSelected={allPhotosSelected}
              onPhotosTagFilter={handlePhotosTagFilter}
              onShowDropzoneSplashEnable={(enable) => setShowDropzoneSplashEnable(enable)}
              onMouseOverSidebar={() => (isSidebarCollapsed ? setIsFlyeroverOpen(true) : null)}
            />
          )}

          {/* People Sidebar */}
          {galleryType === 'private' && (
            <PeopleSidebar
              jobId={jobId}
              biometricsEnabled={jobBiometricsEnabled}
              location={location}
              nextSubject={nextSubject}
              filterPeople={filterPeople}
              searchPhotos={search}
              filterPhotos={filterPhotos}
              photoSelected={photoSelected}
              unselectedPhotos={unselectedPhotos}
              allPhotosSelected={allPhotosSelected}
              shouldClearSubjectsSearchBy={shouldClearSubjectsSearchBy}
              onPhotosFilter={handlePhotosFilter}
              onPhotosPeopleFilter={handlePhotosPeopleFilter}
              onMouseOverSidebar={() => (isSidebarCollapsed ? setIsFlyeroverOpen(true) : null)}
            />
          )}
        </aside>

        {/* Gallery */}
        <section
          className={`job-gallery__container ${isSidebarCollapsed ? 'job-gallery__container--wide' : ''}`}
          onMouseEnter={() => (isFlyoverOpen ? setIsFlyeroverOpen(false) : null)}
        >
          <TopBar
            job={job}
            faces={faces}
            queue={uploadQueue}
            people={people}
            search={search}
            filterTags={filterTags}
            galleryType={galleryType}
            faceSelected={faceSelected}
            filterPhotos={filterPhotos}
            filterPeople={filterPeople}
            photoSelected={photoSelected}
            facesRequesting={facesRequesting}
            unselectedPhotos={unselectedPhotos}
            downloadsDisabled={downloadsDisabled}
            allPhotosSelected={allPhotosSelected}
            uploadDropzoneRef={uploadDropzoneRef}
            qrSessionsTotalErrors={qrSessionsTotalErrors}
            onDeleteShow={handleDeleteShow}
            onPhotosSort={handlePhotosSort}
            onPhotoRemove={handlePhotoRemove}
            onTagModalShow={handleTagModalShow}
            onPhotosSearch={handlePhotosSearch}
            onPhotosFilter={handlePhotosFilter}
            onPhotoSelectAll={handlePhotoSelectAll}
            onShowMatchToggle={handleShowMatchToggle}
            onFaceSelectClear={handleFaceSelectClear}
            onDigitalDownload={handleDigitalDownload}
            onToggleIsMenuOpen={handleToggleIsMenuOpen}
            onPhotoSelectClear={handlePhotoSelectClear}
            onPhotosSearchClear={handlePhotosSearchClear}
            onShowLightboxToggle={handleShowLightboxToggle}
            onPhotosSearchChange={handlePhotosSearchChange}
            onRemoveTagsFromPhotos={handleRemoveTagsFromPhotos}
            onShowBulkFeatureToggle={handleShowBulkFeatureToggle}
            onShowBulkYearbookToggle={handleShowBulkYearbookToggle}
            onQrResolutionModalToggle={handleQrResolutionModalToggle}
            onClearSubjectsSearchBy={() => setShouldClearSubjectsSearchBy(true)}
          />

          {/* Capture QR Warning */}
          {isStudioCaptureQrAllowed && (
            <>
              {isQrDetectionProcessing && (
                <aside className="flex justify-between panel panel--neat panel--warning animate">
                  <p className="m-0">Your images are still processing, please give it a few moments to finish.</p>
                </aside>
              )}
              {!isQrDetectionProcessing && isQrDetectionComplete && qrSessionsTotalErrors > 0 && (
                <aside className="flex justify-between panel panel--neat panel--warning animate">
                  <p className="m-0">
                    There are currently <b>{qrSessionsTotalErrors} unmatched QR records</b>, please resolve these to ensure all of your photos are matched
                    properly.
                  </p>
                  <button className="button button--link text-type-base font-semibold" type="button" name="resolve" onClick={handleQrResolutionModalToggle}>
                    Resolve now
                  </button>
                </aside>
              )}
            </>
          )}

          {/* Dropzone */}
          <UploadDropzone
            ref={uploadDropzoneRef}
            jobId={jobId}
            galleryType={galleryType}
            galleryHasPhotos={photoStats.photos > 0}
            isPngImageAllowed={isPngImageAllowed}
            showDropzone={job.setup_steps?.uploaded_photos === false && photosSuccessful === true && !filterPeople?.id}
            showDropzoneSplash={showDropzoneSplash}
            onDropzoneSplashToggle={handleDropzoneSplashToggle}
          />

          {/* Gallery grids */}
          {(job.setup_steps?.uploaded_photos === true || filterPeople?.id) && (
            <>
              {/* Face grid */}
              {filterPhotos === 'ignored' ? (
                <>
                  {!photosRequesting || !facesRequesting ? (
                    <>
                      {faces.length ? (
                        <ul className="grid gap-5 animate job-gallery__list">
                          {faces.map((face) => (
                            <li
                              className={`job-gallery__item ${faceSelected.includes(face.id) ? 'job-gallery__item--active' : ''}`}
                              key={face.id}
                              onClick={() => handleFaceSelect(face.id)}
                            >
                              <figure className="flex items-center justify-center job-gallery__figure">
                                <LazyLoadImage
                                  className="job-gallery__image"
                                  src={`${face.base_image_url}&rect=${face.crop_rect.x},${face.crop_rect.y},${face.crop_rect.w},${face.crop_rect.h}`}
                                  alt={face.photo_id}
                                  height={260}
                                  draggable="false"
                                  effect="opacity"
                                />
                              </figure>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <aside className="flex items-center justify-center panel panel--secondary panel--tall">
                          <h3 className="m-0">No faces were ignored.</h3>
                        </aside>
                      )}
                    </>
                  ) : (
                    <GridLoader rows={4} columns={5} gap={20} minHeight={225} />
                  )}
                </>
              ) : (
                <>
                  {/* Photo grid */}
                  {photos.length === 0 && !filterPeople?.id && filterPhotos !== 'featured' && (search || filterTags.length) ? (
                    <>
                      {photosRequesting ? (
                        <GridLoader rows={4} columns={5} gap={20} minHeight={225} />
                      ) : (
                        <aside className="flex items-center justify-center panel panel--secondary panel--tall animate">
                          <h3 className="m-0">No photos were found.</h3>
                        </aside>
                      )}
                    </>
                  ) : (
                    <PhotoGrid
                      job={job}
                      photos={photos}
                      people={people}
                      fields={fields}
                      pagination={pagination}
                      filterTags={filterTags}
                      nextSubject={nextSubject}
                      galleryType={galleryType}
                      biometricsEnabled={jobBiometricsEnabled}
                      qrEnabled={jobQrEnabled}
                      filterPhotos={filterPhotos}
                      filterPeople={filterPeople}
                      photoSelected={photoSelected}
                      photosRequesting={photosRequesting}
                      unselectedPhotos={unselectedPhotos}
                      allPhotosSelected={allPhotosSelected}
                      tagManagerOpenList={tagManagerOpenList}
                      shiftHoverSelected={shiftHoverSelected}
                      peopleManagerOpenList={peopleManagerOpenList}
                      isGalleryGridDisabled={shouldGalleryGridBeDisabled()}
                      onAddEditToggle={handlePeopleAddEditToggle}
                      onMouseEnter={handleMouseEnter}
                      onPhotoSelect={handlePhotoSelect}
                      onPhotoFeature={handlePhotoFeature}
                      onPhotoDragEnd={handlePhotoDragEnd}
                      onInfiniteScroll={handleInfiniteScroll}
                      onPhotoDragStart={handlePhotoDragStart}
                      onTagManagerShow={handleTagManagerShow}
                      onDigitalDownload={handleDigitalDownload}
                      onPhotoSelectClear={handlePhotoSelectClear}
                      onNavigateSubjects={handleNavigateSubjects}
                      onPeopleManagerShow={handlePeopleManagerShow}
                      onPhotosPeopleFilter={handlePhotosPeopleFilter}
                      onShowLightboxToggle={handleShowLightboxToggle}
                    />
                  )}
                </>
              )}
            </>
          )}
        </section>

        <GalleryDragElement
          ref={galleryDragElementRef}
          dragItemsTotal={allPhotosSelected ? pagination.total - unselectedPhotos.length : photoSelected.length}
        />
      </main>

      {showLightbox && (
        <Lightbox
          lightboxPhotos={currentPhotoList
            .filter((photo) => photoSelected.includes(photo.id))
            .map((photo) => ({ imageUrl: photo.image_url, imageName: photo.image_filename }))}
          onLightboxClose={handleShowLightboxToggle}
        />
      )}

      {showMatch && (
        <PeopleMatch
          jobId={jobId}
          peopleQuery={peopleQuery}
          filterPeople={filterPeople}
          photoSelected={photoSelected}
          ignoredSelected={faceSelected}
          allPhotosSelected={allPhotosSelected}
          actionType={filterPeople?.id ? 'move' : 'match'}
          onShowMatchToggle={handleShowMatchToggle}
          onPhotosFilter={handlePhotosFilter}
          onPhotosPeopleFilter={handlePhotosPeopleFilter}
          onPhotoSelectClear={handlePhotoSelectClear}
          onFaceSelectClear={handleFaceSelectClear}
        />
      )}

      {showBulkFeature && <BulkFeature jobId={jobId} requesting={peopleRequesting} onShowBulkFeatureToggle={handleShowBulkFeatureToggle} />}
      {showBulkYearbookSelect && <BulkYearbookSelection jobId={jobId} onShowBulkYearbookToggle={handleShowBulkYearbookToggle} />}

      {galleryType === 'private' && (
        <PeopleAddEdit
          map={fieldsMap}
          jobId={job.id}
          studio={studio}
          selectPeople={[filterPeople.id]}
          isPngImageAllowed={isPngImageAllowed}
          showAddEdit={showPeopleAddEdit}
          onAddEditToggle={handlePeopleAddEditToggle}
        />
      )}

      {/* Tag Add modal */}
      {showTag && (
        <aside className="modal job-gallery-tag-modal">
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleTagModalCancel}>
                <i className="icon-close"></i>
              </button>
              <h3>{photoSelected.length > 1 ? 'Add Tags' : 'Manage Tags'}</h3>
            </header>
            <main className="modal__content">
              <p>
                Add a new tag below and press <span className="font-semibold">Enter</span> to confirm it.
              </p>

              <TagManager
                modalView={true}
                filterTags={filterTags}
                filterPhotos={filterPhotos}
                photoSelected={photoSelected}
                unselectedPhotos={unselectedPhotos}
                allPhotosSelected={allPhotosSelected}
                onTagModalCancel={handleTagModalCancel}
                onPhotoSelectClear={handlePhotoSelectClear}
              />
            </main>
          </div>
        </aside>
      )}

      {/* Delete modal */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>
              Delete {allPhotosSelected ? pagination.total - unselectedPhotos.length : photoSelected.length} Photo{photoSelected.length > 1 && 's'}
            </h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to delete the {allPhotosSelected ? pagination.total - unselectedPhotos.length : photoSelected.length} selected photo
              {photoSelected.length > 1 && 's'}?
            </p>
            <p>
              Type <b>"delete"</b> to permanently delete.
            </p>
            <input className="input--block" type="text" name="delete" value={confirmDelete} onChange={handleDeleteChange} maxLength="10" />
          </main>
          <footer className="text-center modal__footer">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={handleDelete}
              disabled={!(confirmDelete.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>

      {/* Photos Onboarding */}
      {showOnboardingPhotos && <GalleryOnboardingPrivate onClose={handleOnboardingPhotos} />}

      {/* QR Resolution Modal */}
      {showQrResolutionModal && <QrResolution qrSessions={jobQrSessions} onQrResolutionModalToggle={handleQrResolutionModalToggle} />}
    </>
  );
}

Gallery.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

export default Gallery;
