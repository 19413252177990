// Plugins
import moment from 'moment';

export const formatCurrency = (number: number = 0, decimalPlaces: number = 2): string => {
  const val = Number(number) / Math.pow(10, decimalPlaces);
  const intValue = Number(val).toLocaleString('en-US', { minimumFractionDigits: 2 });

  return `${intValue}`;
};

export const formatCostRange = (min: number, max?: number): string => {
  if (max) return `$${formatCurrency(min)} - $${formatCurrency(max)}`;
  if (min === max) return `$${formatCurrency(min)}`;

  return `$${formatCurrency(min)} +`;
};

export const formatPercent = (value: number = 0): string => {
  return `${parseFloat((value * 100.0).toFixed(8))}%`;
};

// Dates
export const shortTimeDate = (date?: Date | moment.Moment | string | null): string | null => {
  if (!date || String(date).length === 0) {
    return null;
  }
  return moment.isMoment(date) ? date.local()?.format('L [@]h:mm A') : moment(date).local()?.format('L [@]h:mm A');
};

export const pickerDate = (date?: Date | moment.Moment | null): string | null => {
  if (!date || String(date).length === 0) {
    return null;
  }

  return moment.isMoment(date) ? date.utc().format('YYYY-MM-DD') : moment.utc(date).format('YYYY-MM-DD');
};

export const shortDate = (date?: Date | moment.Moment | string): any => {
  if (!date || !moment(date).isValid()) {
    return null;
  }
  // MM/DD/YYYY
  return moment.isMoment(date) ? date.utc().format('L') : moment.utc(date).format('L');
};

export const mediumDate = (date: Date | moment.Moment): string | null => {
  if (!date || String(date).length === 0) {
    return null;
  }
  // March 15th, 2023
  return moment.isMoment(date) ? date.utc().format('MMMM Do, YYYY') : moment.utc(date).format('MMMM Do, YYYY');
};

export const longDateTime = (date: Date | moment.Moment): string | null => {
  if (!date || !moment(date).isValid()) {
    return null;
  }
  // 03/15/2023 3:45 PM
  return moment.isMoment(date) ? date.utc().format('L LT') : moment.utc(date).format('L LT');
};

export const utcToLocal = (date: Date | moment.Moment): moment.Moment | null => {
  if (!date || !moment(date).isValid()) {
    return null;
  }
  // Converts a given date to UTC and then adjusts it to the local timezone.
  // Returns a Moment object representing the local time equivalent of the input date.
  const utc = moment.utc(date);
  return utc.local();
};

export const convertToUTC = (date: string): Date => {
  const time = new Date(date.split('T')[0]);
  return new Date(time.getTime() + time.getTimezoneOffset() * 60000);
};
