import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import moment from 'moment';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import DatePicker from 'react-datepicker';

// Redux
import { getPeopleList, getSubject, getPeopleFieldOptions, createPeople, updatePeople, createPeopleLookupValidate } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Hooks
import { useJobTimeZone } from '@/hooks';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Styles
import './style.css';

const AddEdit = ({ showAddEdit = false, jobId = '', selectPeople = [], isPngImageAllowed, map = [], onAddEditToggle, onCaptureToggle, studio }) => {
  const dispatch = useDispatch();
  const [jobTimezone] = useJobTimeZone();

  const {
    people: { list, fields, pagination, lookupConfig },
    requesting
  } = useSelector((state) => state.jobs);

  const [information, setInformation] = useState({});
  const [data, setData] = useState([{ value: '', label: '' }]);

  const handleDropAccepted = (file) => {
    const image = file[0];
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      setInformation({
        ...information,
        session_photo_attachment: {
          content: reader.result,
          filename: image.name,
          content_type: image.type
        },
        session_photo_url: URL.createObjectURL(image),
        session_photo_filename: image.name
      });
    };
  };

  const handleRemovePhoto = () => setInformation({ ...information, session_photo_attachment: {}, session_photo_url: '', session_photo_filename: '' });

  const handleInputChange = (e) => {
    setInformation({
      ...information,
      [e.target.name]: e.target.value === 'true' || e.target.value === 'false' ? JSON.parse(e.target.value) : e.target.value
    });
  };

  const handleDateChange = (date, key) => setInformation({ ...information, [key]: date });

  const handleDataChange = (select, index) => {
    setData((prevState) => {
      const prevField = prevState[index].value;
      const prevFieldHasData = information[prevField];
      const newInfo = { ...information };

      if (prevFieldHasData) delete newInfo[prevField];

      data.splice(index, 1, select);
      setInformation(newInfo);

      return data;
    });
  };

  const handleDataAdd = () => setData([...data, { value: '', label: '' }]);

  const handleSave = () => {
    if (information.id) {
      dispatch(
        updatePeople(information, () => {
          onAddEditToggle();
          dispatch(getPeopleFieldOptions({ id: jobId }));
        })
      );
    } else {
      dispatch(
        createPeople({ ...information, id: jobId }, () => {
          onAddEditToggle();
          dispatch(getPeopleFieldOptions({ id: jobId }));
          dispatch(getPeopleList({ id: jobId, page: pagination.page, per_page: Number(pagination.perPage) || 100, order: 'last_name', dir: 'asc' }));

          if (studio.display_capture_message && list.length === 0) {
            onCaptureToggle();
          }

          // Check lookup configuration on very first subject add
          if (lookupConfig.enabled && list.length === 0) {
            dispatch(createPeopleLookupValidate({ jobId, lookupConfig }));
          }
        })
      );
    }
  };

  const loadSubjectData = (subject) => {
    let information = { first_name: '', last_name: '', session_photo_url: '', session_photo_filename: '' };

    if (subject) {
      information = {
        id: subject.id,
        absent: subject.absent,
        first_name: subject.first_name,
        last_name: subject.last_name,
        session_photo_url: subject.session_photo_url,
        session_photo_filename: subject.session_photo_filename,
        ...(subject.session_start ? { session_start: jobTimezone ? moment(subject.session_start)?.tz(jobTimezone)?.format() : '' } : {})
      };

      for (let [key] of Object.entries(fields)) {
        information = { ...information, [key]: subject[key] };
      }
    }

    setInformation(information);
  };

  const supportedImageMimeTypes = { 'image/jpg': ['.jpeg', '.jpg'], ...(isPngImageAllowed ? { 'image/png': ['.png'] } : {}) };

  useEffect(() => {
    if (showAddEdit) {
      let person = list.find((person) => person.id === selectPeople[0]);

      if (person) {
        loadSubjectData(person);
      } else if (selectPeople.length > 0) {
        dispatch(
          getSubject({ id: selectPeople[0] }, ({ data }) => {
            loadSubjectData(data);
          })
        );
      } else {
        loadSubjectData();
      }
    } else {
      setData([{ value: '', label: '' }]);
    }
  }, [showAddEdit]);

  return (
    <aside className={`modal ${showAddEdit ? '' : 'transparent'}`}>
      <div className="modal__box modal__box--secondary job-people-addedit">
        <button className="button button--action modal__close" name="close" type="button" onClick={onAddEditToggle}>
          <i className="icon-close"></i>
        </button>
        <main className="flex modal__content">
          <section className="flex basis-5/12 sm:basis-full flex-col flex-nowrap justify-between modal__content-section">
            <header>
              <h3 className="text-headline-sm">Add Reference Photo</h3>
              <p>This photo will be used to match uploaded picture day photos</p>
            </header>

            <article className="flex flex-col justify-center items-center">
              <Dropzone
                multiple={false}
                maxSize={15000000}
                accept={supportedImageMimeTypes}
                noClick={false}
                noKeyboard={true}
                noDragEventsBubbling={true}
                preventDropOnDocument={true}
                onDropAccepted={handleDropAccepted}
              >
                {({ getRootProps, getInputProps }) => (
                  <figure className="job-people-addedit__figure" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {information.session_photo_url && (
                      <img
                        className="job-people-addedit__image"
                        src={imageScaling({
                          url: information.session_photo_url,
                          size: information.session_photo_attachment && information.session_photo_attachment.content ? null : 'small'
                        })}
                        alt={information.session_photo_filename}
                        width="150"
                      />
                    )}
                  </figure>
                )}
              </Dropzone>
              <button className="button button--link mb-5" name="remove" type="button" onClick={handleRemovePhoto} disabled={!information.session_photo_url}>
                <small>Remove</small>
              </button>
            </article>

            <footer className="panel panel--lean panel--dark">
              <h6>Reference Photo Tips</h6>
              <ul>
                <li>
                  <small>• At least 300 pixels wide</small>
                </li>
                <li>
                  <small>• Photo must be 15MB or less</small>
                </li>
                <li>
                  <small>• Taken in front of a plain white or off-white background</small>
                </li>
                <li>
                  <small>• Taken in full-face view directly facing the camera</small>
                </li>
                <li>
                  <small>• With a neutral facial expression and both eyes open</small>
                </li>
              </ul>
            </footer>
          </section>

          <section className="basis-7/12 sm:basis-full modal__content-section">
            <h3 className="text-headline-sm">Edit Subject Data</h3>
            <form className="job-people-addedit__form">
              <fieldset className="mb-5">
                <label htmlFor="first-name">
                  Name <small>(required)</small>
                </label>
                <div className="flex gap-4">
                  <div className="basis-6/12 sm:basis-full">
                    <input
                      id="first-name"
                      type="text"
                      name="first_name"
                      value={information.first_name || ''}
                      onChange={handleInputChange}
                      placeholder="First Name"
                      maxLength="50"
                      required
                    />
                  </div>
                  <div className="basis-6/12 sm:basis-full">
                    <input
                      type="text"
                      name="last_name"
                      value={information.last_name || ''}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                      maxLength="50"
                      required
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset className="mb-0">
                {Object.keys(information)
                  .filter(
                    (key) =>
                      Object.keys(fields).includes(key) &&
                      !data.some((item) => item.value === key) &&
                      key !== 'first_name' &&
                      key !== 'last_name' &&
                      key !== 'absent' &&
                      key !== 'image_name'
                  )
                  .map((key) => (
                    <div className={`flex flex-wrap ${key.toLowerCase().includes('email') ? 'basis-full' : 'basis-6/12'} mb-5`} key={key}>
                      <label className="capitalize" htmlFor={key}>
                        {key.replace('session_start', 'check-in_date').replace('_', ' ')}
                      </label>
                      {key === 'session_start' ? (
                        <>
                          <DatePicker
                            className="input--date"
                            name={key}
                            placeholderText="MM/DD/YY"
                            selected={information[key]}
                            onChange={(date) => handleDateChange(date, key)}
                          />
                        </>
                      ) : (
                        <input
                          id={key}
                          type={`${key.toLowerCase().includes('period') ? 'number' : 'text'}`}
                          name={key}
                          value={information[key] || ''}
                          onChange={handleInputChange}
                          maxLength={key.toLowerCase().includes('email') ? '200' : '50'}
                          readOnly={key === 'access_id' || key === 'added_by'}
                        />
                      )}
                    </div>
                  ))}
              </fieldset>
              <fieldset className="mb-5">
                <label htmlFor="label">Data</label>
                {data.map((item, index) => (
                  <div className="flex gap-4 mb-5" key={item.value + index}>
                    <Select
                      className="basis-6/12 select"
                      classNamePrefix="select"
                      placeholder="Select data"
                      value={item.value ? item : ''}
                      options={map.filter(
                        (item) =>
                          !Object.keys(information).includes(item.value) &&
                          item.value !== 'image_name' &&
                          data.every((dataItem) => item.value !== dataItem.value)
                      )}
                      onChange={(select) => handleDataChange(select, index)}
                    />
                    <input
                      className="basis-6/12"
                      type={`${item.value.toLowerCase().includes('period') ? 'number' : 'text'}`}
                      name={item.value}
                      value={information[item.value] || ''}
                      onChange={handleInputChange}
                      placeholder="Enter data"
                      maxLength={item.value.toLowerCase().includes('email') ? '200' : '50'}
                    />
                  </div>
                ))}
                {data.length < map.length - 2 && (
                  <button
                    className="button button--blue-outline button--block"
                    type="button"
                    name="another"
                    onClick={handleDataAdd}
                    disabled={!data.slice(-1)[0]['value']}
                  >
                    + Add data
                  </button>
                )}
              </fieldset>
              <hr />
              <fieldset className="flex flex-col">
                <label htmlFor="absent">Mark subject absent?</label>
                <div className="flex gap-4">
                  <div className="flex flex-nowrap items-center basis-2/6 sm:basis-6/12">
                    <input id="absent-no" type="radio" name="absent" value={false} checked={information.absent === false} onChange={handleInputChange} />
                    <label htmlFor="absent-no" className="m-0">
                      No
                    </label>
                  </div>
                  <div className="flex flex-nowrap items-center basis-2/6 sm:basis-6/12">
                    <input id="absent-yes" type="radio" name="absent" value={true} checked={information.absent === true} onChange={handleInputChange} />
                    <label htmlFor="absent-yes" className="m-0">
                      Yes
                    </label>
                  </div>
                </div>
              </fieldset>
            </form>
            <footer className="job-people-addedit__footer">
              <button
                className="button button--medium"
                name="save"
                type="button"
                onClick={handleSave}
                data-loading={requesting}
                disabled={requesting || !(information.first_name && information.last_name)}
              >
                Save
              </button>
            </footer>
          </section>
        </main>
      </div>
    </aside>
  );
};

AddEdit.propTypes = {
  showAddEdit: PropTypes.bool.isRequired,
  jobId: PropTypes.string.isRequired,
  selectPeople: PropTypes.array.isRequired,
  map: PropTypes.array.isRequired
};

export default AddEdit;
